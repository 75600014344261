
























































































































































































































































































































































































































































































































































































































































.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.model {
  .conTip {
    text-align: justify;
  }
  .itemLabel {
    margin: 10px 0;
  }
}
